.ConnectUsContainer {
  width: 100vw;

  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    background: #fff;
    padding-top: 2rem;
    padding-left: 3.75rem;
    margin-bottom: 5.5rem;

    &__title {
      display: flex;

      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }

      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }

      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &_company {
      background: #fff;
      display: flex;
      justify-content: space-between;
      padding: 6.25rem 3.375rem;

      &__left {
        width: 60%;

        &__title {
          width: 6rem;
          background: rgba(232, 232, 232, 0.5);
          backdrop-filter: blur(20px);
          border-left: 4px solid #ff8629;
          height: 1.4375rem;
          color: #434343;
          font-family: Rubik;
          line-height: 1.4375rem;
          font-size: 0.875rem;
          display: flex;
          justify-content: center;
        }

        &__text {
          color: #434343;
          font-family: Alibaba PuHuiTi 2;
          font-size: 2.1875rem;
          font-weight: 600;
          margin-top: 1.875rem;
        }

        &__content {
          margin-top: 8rem;

          &__item {
            display: flex;
            margin-bottom: 2.75rem;

            &__icon {
              width: 1.875rem;
              height: 1.875rem;
              flex-shrink: 0;
            }

            &__text {
              color: #1c1f35;
              font-family: Rubik;
              font-size: 1.25rem;
              font-weight: 400;
              margin-left: 2rem;
            }
          }
        }
      }

      &__right {
        margin-left: 8.125rem;
        margin-top: 3.125rem;
        width: 55%;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }

  &-bottom {
    background-image: url(../assets//image/Rectangle195.png);
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 47.8125rem;

    display: flex;
    padding: 6.25rem 13.3125rem 6.25rem 13.3125rem;

    &__left {
      width: 30%;
      &__title {
        width: 6rem;
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 1.4375rem;
        color: #fff;
        font-family: Rubik;
        line-height: 1.4375rem;
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
      }

      &__text {
        color: #fff;
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        margin-top: 1rem;
      }

      &__content {
        color: #fff;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-weight: 300;
        letter-spacing: 0.15rem;
        margin-top: 1rem;
        width: 22.0625rem;
      }
    }

    &__right {
      width: 70%;
      margin-left: 12rem;
    }
  }
}

@root-entry-name: default;
.productDetailContainer {
  width: 100vw;
  &-titleImg {
    background-size: 100% 100%;
    background-repeat: no-repeat;
    height: 75vh;
  }

  &-content {
    background: #fff;
    padding-top: 2.81rem;
    padding-left: 3.81rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }
      &__textNav {
        margin-left: 1rem;
        color: #637381;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        cursor: pointer;
      }
      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &-data {
    margin-top: 50px;
    // margin-bottom: 50px;
    &__imgBox {
      width: 100%;
      height: 100%;
      &__img {
        width: 100%;
        height: 100%;
      }
    }

    .empty {
      margin-left: 40%;
      width: 18.75rem;
      height: 18.75rem;
      padding-bottom: 50px;
      img {
        width: 100%;
        height: 100%;
      }
      &_title {
        color: var(---gray-12, #141414);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2rem;
        text-align: center;
        margin-top: 15px;
      }
      &_text {
        color: var(---gray-8, #595959);
        text-align: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1.25rem;
        margin-top: 10px;
      }
    }
  }

  &-line {
    margin-top: 100px;
    height: 1px;
    background-color: #8c8c8c;
    margin-left: 60px;
    margin-right: 60px;
    margin-bottom: 50px;
  }
}

@root-entry-name: default;
.nopadding {
  padding: 0 !important;
}

.nomargin {
  margin: 0 !important;
}

.noscroll {
  &::-webkit-scrollbar {
    width: 0 !important;
    height: 0 !important;
  }
}

@root-entry-name: default;
.AboutUsContainer {
  width: 100vw;

  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &_introduce {
    background: var(--gray-12, #141414);
    display: flex;
    justify-content: space-between;
    padding: 6.25rem 3.75rem 12.5rem 3.75rem;

    &__left {
      &__title {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 6.8125rem;
        height: 1.4375rem;
        color: var(--fore-allways-white, #fff);
        font-family: Rubik;
        line-height: 1.4375rem;
        font-size: 0.875rem;
        display: flex;
        justify-content: center;
      }

      &__text {
        color: var(--fore-allways-white, #fff);
        font-family: Alibaba PuHuiTi 2;
        font-size: 35px;
        font-weight: 600;
        margin-top: 1.875rem;
      }
    }

    &__right {
      margin-top: 3.125rem;
      color: var(--fore-allways-white, #fff);
      font-family: Alibaba PuHuiTi 2;
      font-size: 4.6875rem;
      font-style: normal;
      font-weight: 300;
    }
  }

  &_swiper {
    padding-top: 5rem;
    background: var(--gray-12, #141414);
    padding-bottom: 5rem;
    overflow: hidden;
    width: 100%;

    &__track {
      white-space: nowrap;

      .slide {
        width: 100%;

        .AboutUsContainer_swiper__track__item {
          width: 30.75rem;
          height: 20.5rem;
          border: 1px solid salmon;
        }
      }
    }
  }

  &_swiperLine {
    background: var(--gray-12, #141414);
    height: 150px;
    padding-left: 3.88rem;
    padding-right: 3.88rem;
    // &__line {
    //   height: 1px;
    //   background-color: #8c8c8c;
    // }
  }

  &_company {
    background: #fff;
    display: flex;
    justify-content: space-between;
    padding: 100px 60px 100px 60px;

    &__left {
      width: 50%;
      margin-right: 13.3125rem;
      display: flex;
      flex-direction: column;

      &__title {
        width: 5.625rem;
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 1.4375rem;
        color: #434343;
        font-size: 0.875rem;
        font-family: Rubik;
        line-height: 1.4375rem;
        display: flex;
        justify-content: center;
      }

      &__text {
        color: #434343;
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-weight: 600;
        margin-top: 1.875rem;
      }

      &__content {
        color: var(---gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 1.5rem;
        font-weight: 300;
      }
    }

    &__right {
      width: 50%;
      margin-top: 3.75rem;
    }
  }

  &_bottomLine {
    margin: 6.25rem 3.75rem;
    height: 1px;
    background-color: #8c8c8c;
  }

  &_bottom {
    position: relative;

    .AboutUsContainer_bottomImg {
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-top {
      position: absolute;
      top: 25%;
      width: 100%;
      text-align: center;

      &_title {
        color: var(--fore-allways-white, #fff);
        text-align: center;
        font-size: 4.6875rem;
        font-family: Alibaba PuHuiTi 2;
      }

      &_footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 12.7rem;
        height: 4.7rem;
        flex-shrink: 0;
        border-radius: 4.375rem;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        margin-top: 1.5625rem;

        &__name {
          color: var(--fore-allways-white, #fff);
          text-align: center;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.3324rem;
          font-weight: 500;
        }

        &__icon {
          background-image: url(../assets/icon/Down.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 0.9375rem;
          height: 0.5rem;
          margin-left: 0.625rem;
        }
      }

      &_footer:hover {
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
      }
    }
  }
}

.scroll_div {
  width: 100%;
  height: 20.5rem;
  margin: 0 0;
  overflow: hidden;
  white-space: nowrap;
  background: #141414;
}

.scroll_div img {
  width: 30.75rem;
  height: 20.5rem;
  margin-left: 1.875rem;
}

#scroll_begin,
#scroll_end,
#scroll_begin ul,
#scroll_end ul,
#scroll_begin ul li,
#scroll_end ul li {
  display: inline;
}

/*设置ul和li横排*/

@root-entry-name: default;
.VedioContanier__box {
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
  &__video {
    width: 75rem;
    height: 42.1875rem;
    position: relative;
    img {
      width: 100%;
      height: 100%;
      border-radius: 1.25rem;
    }
    video {
      width: 100%;
      height: 100%;
      border-radius: 1.25rem;
    }
    &__name {
      position: absolute;
      top: 1.25rem;
      left: 1.25rem;
      font-size: 1.5625rem;
      color: rgba(255, 255, 255, 1);
    }
    &__Icon {
      width: 8.375rem;
      height: 6.125rem;
      position: absolute;
      top: 45%;
      left: 45%;
      cursor: pointer;
      img {
        width: 100%;
        height: 100%;
      }
      video {
        width: 100%;
        height: 100%;
      }
    }
  }
}
.VedioContanier__bottom {
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
  &__left {
    cursor: pointer;
    width: 3.75rem;
    height: 3.75rem;
    margin-top: 3rem;
    margin-right: 1.25rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  &__center {
    display: flex;
    // justify-content: space-between;
    overflow: hidden;
    width: 75rem;
    &__box {
      width: 17rem;
      height: 12.8125rem;
      margin-right: 2.1875rem;
      cursor: pointer;
      &__img {
        width: 17rem;
        height: 9.5625rem;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
        }
      }
      &__name {
        display: flex;
        justify-content: center;
        text-align: center;
        margin-top: 0.625rem;
      }
    }
  }
  &__right {
    cursor: pointer;
    width: 3.75rem;
    height: 3.75rem;
    margin-top: 3rem;
    margin-left: 1.25rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
}

@root-entry-name: default;
.RecruitmentDetailContainer {
  width: 100vw;
  &__title {
    width: 100vw;
    background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
    color: var(--fore-allways---white, #fff);
    font-family: Alibaba PuHuiTi 2;
    font-size: 1.5rem;
    height: 6.25rem;
    line-height: 6.25rem;
    padding-left: 13.3125rem;
  }
  &__content {
    background-color: #f5f5f5;
    padding-top: 5.0625rem;
    padding-bottom: 9.375rem;
    position: relative;
    display: flex;
    justify-content: center;
    &__center {
      border-radius: 1.5625rem;
      background: #fff;
      width: 55.25rem;
      padding: 2.625rem;
      &__title {
        border-bottom: 1px solid #d9d9d9;
        padding-bottom: 1.25rem;
        &__top {
          display: flex;
          justify-content: space-between;
          &__left {
            display: flex;
            .isUrgent {
              color: #ff7b7b;
              font-family: Alibaba PuHuiTi 2;
              font-size: 0.875rem;
              font-style: normal;
              font-weight: 500;
              border-radius: 3px;
              background: #ffccca;
              width: 1.5rem;
              height: 1.5rem;
              margin-top: 0.4375rem;
              text-align: center;
              margin-right: 0.9375rem;
            }
            .positionName {
              color: #000;
              font-family: Alibaba PuHuiTi 2;
              font-size: 1.5rem;
              font-style: normal;
              font-weight: 500;
            }
          }
          &__right {
            display: flex;
            margin-top: 0.3125rem;
            cursor: pointer;
            .shareIcon {
              width: 1.125rem;
              height: 1.0488rem;
            }
            .share {
              color: var(---gray-7, #8c8c8c);
              font-family: Alibaba PuHuiTi 2;
              font-size: 0.75rem;
              margin-left: 0.3125rem;
            }
          }
        }
        &__foot {
          margin-top: 0.9375rem;
          display: flex;
          .department {
            color: #8c8c8c;
            font-family: Alibaba PuHuiTi 2;
            font-size: 0.75rem;
          }
          .pubTime {
            color: #8c8c8c;
            font-family: Alibaba PuHuiTi 2;
            font-size: 0.75rem;
            margin-top: 0.3125rem;
          }
        }
      }
      &__con {
        margin-top: 0.9375rem;
        padding-bottom: 1.875rem;
      }
    }
    &__btn {
      cursor: pointer;
      margin-top: 1.875rem;
      border-radius: 49px;
      background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
      color: var(--fore-allways---white, #fff);
      font-family: Alibaba PuHuiTi 2;
      font-size: 1rem;
      font-weight: 600;
      width: 8.4375rem;
      height: 3.25rem;
      text-align: center;
      line-height: 3.25rem;
      position: absolute;
      bottom: 3.125rem;
    }
  }
}

@root-entry-name: default;
.HomePageContainer {
  width: 100vw;
  .custom-card {
    .ant-card-body {
      background-color: #f5f5f5;
    }
  }

  &_swiper {
    height: 57.75rem;

    &__item {
      background-size: 100% 100%;
      background-repeat: no-repeat;
      height: 57.75rem;
      display: flex;
      flex-direction: column;
      justify-content: center;
      text-align: center;
      align-items: center;
      position: relative;

      &__title {
        color: var(--fore-allways-white, #fff);
        font-family: Alibaba PuHuiTi 2;
        font-size: 6.6668rem;
        font-style: normal;
        font-weight: 700;
        line-height: normal;
        margin-bottom: 0.625rem;
      }

      &__content {
        color: var(--fore-allways-white, #fff);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2rem;
        white-space: break-spaces;
      }

      &__bottom {
        position: absolute;
        bottom: 2.875rem;
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;

        &__text {
          color: var(--fore-allways-white, #fff);
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.1667rem;
        }

        &__icon {
          position: absolute;
          width: 1.5rem;
          height: 1.375rem;
          left: 50%;
          transform: translateX(-50%);
          animation: moveUpDown 1.5s infinite alternate;
        }

        @keyframes moveUpDown {
          0% {
            top: 1.8rem;
          }

          100% {
            top: 2.3rem;
          }
        }
      }
    }
  }

  &_introduce {
    min-height: 35rem;
    background: var(--gray-12, #141414);
    display: flex;
    justify-content: space-between;
    padding: 8.75rem 1.875rem 6.25rem 3.75rem;

    &__left {
      width: 70%;

      &__title {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 1.4375rem;
        width: 6.125rem;
        color: var(--fore-allways-white, #fff);
        font-family: Rubik;
        padding-left: 0.3125rem;
        line-height: 1.4375rem;
        font-size: 0.875rem;
      }

      &__text {
        color: var(--fore-allways-white, #fff);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        margin-top: 1.875rem;
        white-space: break-spaces;
      }
    }

    &__right {
      width: 70%;
      margin-top: 3.125rem;
      color: var(--fore-allways-white, #fff);
      font-family: Alibaba PuHuiTi 2;
      font-size: 2.25rem;
      font-style: normal;
      font-weight: 300;
    }
  }

  &_advantage {
    background: var(--gray-12, #141414);

    &__box {
      display: flex;
      justify-content: space-between;
      margin-bottom: 4.0625rem;

      &__item {
        display: flex;
        color: rgba(255, 255, 255, 0.61);
        padding-left: 3.75rem;
        width: 26.875rem;
        cursor: pointer;

        &__icon {
          width: 3.125rem;
          height: 3.125rem;
          margin-right: 0.875rem;
          margin-top: 0.3125rem;
        }

        &__text {
          padding-bottom: 1.875rem;
        }

        &__textChoose {
          padding-bottom: 1.875rem;
        }

        &__text div:nth-child(1) {
          font-size: 1.625rem;
          font-weight: 300;
        }

        &__text div:nth-child(2) {
          font-size: 1.125rem;
          font-weight: 300;
        }

        &__textChoose div:nth-child(1) {
          font-size: 1.625rem;
          font-weight: 300;
        }

        &__textChoose div:nth-child(2) {
          font-size: 1.125rem;
          font-weight: 300;
        }

        &__itemLine {
          height: 1px;
          background-color: #fff;
          width: 26.875rem;
        }
      }

      &__itemChoose {
        color: var(--fore-allways-white, #fff);
        display: flex;
        padding-left: 3.75rem;
        width: 26.875rem;
        cursor: pointer;
      }

      .HomePageContainer_advantage__box__itemCon {
        display: flex;
        border-bottom: 1px solid rgba(255, 255, 255, 0.61);
      }

      .HomePageContainer_advantage__box__itemConChoose {
        display: flex;
        border-bottom: 1px solid var(--fore-allways-white, #fff);
      }

      &__itemChoose:last-child {
        margin-right: 3.75rem;
      }

      &__item:last-child {
        margin-right: 3.75rem;
      }
    }

    &__img {
      width: 100%;
    }

    &__numBox {
      display: flex;
      justify-content: space-between;
      overflow: hidden;
      position: relative;
      top: -781px;

      &__num {
        color: rgba(255, 255, 255, 0.3);
        font-family: Poppins;
        font-size: 150px;
        font-weight: 500;
        padding-left: 60px;
        padding-right: 60px;
        margin-top: -80px;
      }

      &__numChoose {
        color: var(--fore-allways-white, #fff);
        font-family: Poppins;
        font-size: 150px;
        font-weight: 500;
        padding-left: 60px;
        padding-right: 60px;
        margin-top: -80px;
      }
    }
  }

  &_product {
    background: #fff;
    padding: 6.25rem 3.75rem 6.25rem 3.75rem;

    &__left {
      margin-left: 0.8125rem;

      &__title {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 1.4375rem;
        width: 6.625rem;
        color: var(--gray-9, #434343);
        font-family: Rubik;
        padding-left: 0.3125rem;
        line-height: 1.4375rem;
        font-size: 0.875rem;
      }

      &__text {
        margin-top: 1.875rem;
        color: var(--gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 600;
      }
    }

    &__box {
      display: flex;
      justify-content: space-between;
    }

    &__boxCon {
      width: 25%;

      img {
        width: 100%;
        height: 90%;
        margin-top: 1.875rem;
        border-radius: 1.5625rem;
        margin-right: 2rem;
        cursor: pointer;
      }
    }
  }

  &_voice {
    height: 1080px;
    background: var(--gray-2, #fafafa);
    padding: 100px 60px 100px 60px;

    &__left {
      &__title {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 23px;
        width: 102px;
        color: var(--gray-9, #434343);
        font-family: Rubik;
        padding-left: 5px;
        line-height: 23px;
      }

      &__text {
        margin-top: 30px;
        color: var(--gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 35px;
        font-style: normal;
        font-weight: 600;
        line-height: 161%;
      }
    }

    &__box {
      display: flex;
      justify-content: space-between;
      padding-top: 60px;

      &__item {
        border-radius: 25px;
        background: var(--white, #fff);
        box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
        width: 25%;
        margin-right: 32px;

        &__title {
          position: relative;
          border-bottom: 1px solid #8c8c8c;
          padding-bottom: 144px;

          &__num {
            color: var(--gray-2, #fafafa);
            font-family: Poppins;
            font-size: 150px;
            font-weight: 500;
            position: absolute;
            left: 30px;
            top: -100px;
          }

          &__dots {
            background-color: #ed632d;
            width: 7px;
            height: 7px;
            border-radius: 50%;
            position: absolute;
            right: 30px;
            top: 33px;
          }
        }

        &__content {
          color: var(--gray-9, #434343);
          font-family: Alibaba PuHuiTi 2;
          font-size: 18px;
          font-weight: 500;
          padding: 24px 44px 24px 44px;
          height: 400px;
        }

        &__footer {
          border-top: 1px solid #8c8c8c;
          padding: 24px 44px 24px 44px;
        }

        &__footer div:nth-child(1) {
          color: var(--gray-12, #141414);
          font-family: Alibaba PuHuiTi 2;
          font-size: 18px;
          font-weight: 500;
        }

        &__footer div:nth-child(2) {
          color: var(--gray-7, #8c8c8c);
          font-family: Poppins;
          font-size: 18px;
          font-weight: 500;
        }

        &__footer div:nth-child(3) {
          background: var(
            --2,
            linear-gradient(270deg, #ff7a00 0%, #ff4d00 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          font-family: Alibaba PuHuiTi 2;
          font-size: 18px;
          font-weight: 500;
        }
      }

      &__item:last-child {
        margin-right: 0;
      }
    }
  }

  &_news {
    background: #fafafa;
    padding-bottom: 3.125rem;

    &__title {
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-direction: column;
      padding-top: 6.25rem;

      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        height: 1.4375rem;
        width: 6.375rem;
        color: var(--gray-9, #434343);
        font-family: Rubik;
        padding-left: 0.3125rem;
        line-height: 1.4375rem;
        font-size: 0.875rem;
      }

      &__text {
        color: var(--gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-weight: 600;
        margin-top: 1rem;
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      padding-top: 3.75rem;

      &__item {
        margin-right: 1.5rem;
        border-radius: 1.2969rem;
        background: var(--white, #fff);
        overflow: hidden;
        cursor: pointer;
        width: 30rem;
        height: 31.25rem;
        &__img {
          width: 100%;
          height: 65%;
          img {
            width: 100%;
            height: 100%;
          }
        }

        &__foot {
          border-bottom-left-radius: 1.2969rem;
          border-bottom-right-radius: 1.2969rem;
          display: flex;
          padding: 1.2969rem;
          padding-bottom: 0;

          &__left {
            border-right: 1px solid #d6d6d6;
            padding-right: 1.875rem;
            display: flex;
            flex-direction: column;
            align-items: center;

            &__icon {
              width: 2.5rem;
              height: 2.8125rem;
            }

            &__month {
              color: var(--gray-10, #262626);
              text-align: center;
              font-family: Rubik;
              font-size: 2.075rem;
              font-weight: 600;
            }

            &__monthEn {
              color: var(--gray-7, #8c8c8c);
              text-align: center;
              font-family: Krub;
              font-size: 0.83rem;
              font-weight: 500;
            }
          }

          &__right {
            padding-left: 1.25rem;
            display: flex;
            flex-direction: column;
            justify-content: space-between;

            &__title {
              color: var(--gray-10, #262626);
              font-family: Noto Sans HK;
              font-size: 1.1rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &__content {
              color: var(--gray-7, #8c8c8c);
              font-family: Noto Sans HK;
              font-size: 0.8rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }
          }
        }
      }

      &__item:hover {
        box-shadow: 0px 0.625rem 0.9375rem 0px rgba(5, 13, 29, 0.18);
      }

      &__item:hover .HomePageContainer_news__box__item__foot__right__title {
        background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2;
        /* 限制在两行 */
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
  }

  &_bottom {
    position: relative;

    .HomePageContainer_bottomImg {
      img {
        width: 100%;
        height: 100%;
      }
    }

    &-top {
      width: 100%;
      position: absolute;
      top: 25%;
      left: 50%;
      transform: translateX(-50%);

      &_title {
        color: var(--fore-allways-white, #fff);
        text-align: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 4.6875rem;
      }

      &_footer {
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 12.7rem;
        height: 4.7rem;
        flex-shrink: 0;
        border-radius: 4.375rem;
        background: rgba(255, 255, 255, 0.3);
        display: flex;
        justify-content: center;
        align-items: center;
        text-align: center;
        cursor: pointer;
        margin-top: 1.5625rem;

        &__name {
          color: var(--fore-allways-white, #fff);
          text-align: center;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.3324rem;
          font-weight: 500;
        }

        &__icon {
          background-image: url(../assets/icon/Down.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 0.9375rem;
          height: 0.5rem;
          margin-left: 0.625rem;
        }
      }

      &_footer:hover {
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
      }
    }
  }
}

@root-entry-name: default;
.productContainer {
  width: 100vw;
  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-content {
    background: #fff;
    padding-top: 2rem;
    padding-left: 60px;
    padding-right: 60px;
    margin-bottom: 5.5rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }

      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &__center {
      margin-top: 2.38rem;
      display: flex;
      &__left {
        width: 65%;
        &__img {
          width: 100%;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &__bottom {
          display: flex;
          margin-top: 1rem;
          &__Left {
            width: 50%;
            margin-right: 1rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
          &__Right {
            width: 50%;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
      }
      &__right {
        margin-left: 1rem;
        width: 35%;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}

@root-entry-name: default;
.Faq_center {
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
  .Faq_center__box {
    width: 73.75rem;

    .ant-collapse-item {
      background-color: rgba(250, 250, 250, 1);
      margin-bottom: 0.625rem;
      .ant-collapse-expand-icon {
        span {
          color: #ff8629;
        }
      }
      .ant-collapse-header-text {
        color: rgba(0, 0, 0, 1);
        font-size: 1.125rem;
        font-weight: 700;
      }
      .ant-collapse-content-box {
        p {
          color: rgba(140, 140, 140, 1);
          font-size: 1rem;
        }
      }
    }
  }
}
.Faq_Pagination {
  margin-top: 3.125rem;
  display: flex;
  justify-content: center;
  margin-left: -3.75rem;
}

@root-entry-name: default;
.recruitmentContainer {
  width: 100vw;
  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    background: #f5f5f5;
    padding-top: 2.81rem;
    padding-left: 3.81rem;
    padding-bottom: 5rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }

      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &__center {
      margin-top: 3rem;
      display: flex;
      justify-content: center;
      text-align: center;
      align-items: center;
      flex-direction: column;
      margin-left: -5rem;
      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 6rem;
        height: 1.4375rem;
        color: #1c1f35;
        font-family: Rubik;
        line-height: 1.4375rem;
        display: flex;
        font-size: 0.875rem;
        justify-content: center;
      }
      &__flex {
        color: var(---gray-9, #434343);
        text-align: center;
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-weight: 600;
        margin-top: 1rem;
      }
    }

    &__foot {
      background: #fff;
      border-radius: 1.875rem;
      margin-left: 10rem;
      margin-right: 10rem;
      margin-top: 3rem;
      padding: 1.25rem 4.375rem 2.625rem 4.375rem;
      &__Pagination {
        margin-top: 3rem;
        margin-left: -5rem;
        margin-bottom: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }

    &__box {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      margin-left: 0.25rem;
      margin-right: 3.25rem;
      margin-bottom: 1.875rem;
      &__item {
        width: 36.1875rem;
        height: 15.625rem;
        cursor: pointer;
        background-color: #f5f5f5;
        margin-right: 1.875rem;
        margin-top: 1.875rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;
        align-items: center;
        &__imgBox {
          width: 3.875rem;
          height: 3.75rem;
          &__pic {
            width: 100%;
            height: 100%;
          }
        }
        &__name {
          margin-top: 1.25rem;
          font-size: 1.5rem;
          color: rgba(20, 20, 20);
          // font-weight: 700;
        }
      }
    }
  }
}

@root-entry-name: default;
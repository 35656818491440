.photovoltaicContainer {
  width: 100vw;
  overflow: hidden;
  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-content {
    background: #f5f5f5;
    padding-top: 2rem;
    padding-left: 3.81rem;
    margin-bottom: 5.5rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }
      &__textNav {
        margin-left: 1rem;
        color: #637381;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        cursor: pointer;
      }
      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &__box {
      display: flex;
      padding: 0.375rem 8.3125rem 4.375rem 8.3125rem;
      margin-top: 4rem;
      padding-bottom: 3.37rem;
      &__item {
        width: 21.8125rem;
        height: 10.8125rem;
        border-radius: 1.5625rem;
        border: 2px solid var(--Linear, #ffa800);
        background: #fff;
        text-align: center;
        align-items: center;
        padding-top: 1.875rem;
        margin-right: 1.875rem;
        cursor: pointer;
        &__icon {
          width: 3rem;
          height: 3rem;
        }
        &__text {
          color: var(--neutral-07100, #141718);
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.2rem;
          margin-top: 1.5rem;
        }
      }
      &__itemUn {
        border: 0;
        width: 21.8125rem;
        height: 10.8125rem;
        border-radius: 1.5625rem;
        background: #fff;
        text-align: center;
        align-items: center;
        padding-top: 1.875rem;
        margin-right: 1.875rem;
        cursor: pointer;
      }
    }
  }
  &-kernel {
    background: #fff;
    height: 1000px;

    &__box {
      display: flex;
      margin-left: 201px;
      margin-right: 211px;

      &__left {
        width: 50%;
        height: 885px;
        background: #f5f5f5;
        border-radius: 25px;
        padding: 60px;
        &__title {
          color: #141718;
          font-feature-settings:
            'clig' off,
            'liga' off;
          font-family: Poppins;
          font-size: 45.286px;
          font-style: normal;
          font-weight: 500;
        }
        &__sub {
          display: flex;
          margin-top: 10px;
          justify-content: space-between;
          .sub__left {
            width: 60%;
            color: var(--neutral-07100, #141718);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Alibaba PuHuiTi 2;
            font-size: 21.311px;
            font-weight: 500;
            &__toDetailIcon {
              margin-left: 10px;
            }
            .sub__leftLine {
              margin-top: 5px;
              height: 1px;
              width: 110px;
              background-color: #141718;
            }
          }
          .sub__right {
            width: 40%;
            color: var(---gray-7, #8c8c8c);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Alibaba PuHuiTi 2;
            font-size: 16px;
            word-wrap: break-word;
          }
        }
        &__imgBox {
          width: 638px;
          height: 602px;
          // margin-top: 50px;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
      &__left:hover {
        .photovoltaicContainer-kernel__box__left__title,
        .sub__left {
          cursor: pointer;
          background: var(
            --Linear,
            linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          .sub__left__toDetailIcon {
            color: #ff5207;
          }
          .sub__leftLine {
            margin-top: 5px;
            height: 1px;
            width: 110px;
            background-color: #ff5207;
          }
        }
      }
      &__right {
        width: 50%;
        height: 885px;
        margin-left: 30px;
        &__top {
          background: #f5f5f5;
          border-radius: 25px;
          display: flex;
          justify-content: space-between;
          width: 732px;
          height: 425px;
          flex-shrink: 0;
          padding: 60px;
          &__left {
            &__name {
              color: #141718;
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Poppins;
              font-size: 45.286px;
              font-style: normal;
              font-weight: 500;
            }
            &__toDetail {
              margin-top: 10px;
              color: var(--neutral-07100, #141718);
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Alibaba PuHuiTi 2;
              font-size: 21.311px;
              font-weight: 500;
            }
            &__toDetailLine {
              margin-top: 5px;
              height: 1px;
              width: 110px;
              background-color: #141718;
            }
            &__content {
              color: var(---gray-7, #8c8c8c);
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Alibaba PuHuiTi 2;
              font-size: 16px;
              margin-top: 100px;
            }
          }
          &__right {
            margin-left: 20px;
            img {
              width: 311px;
              height: 291px;
            }
          }
        }

        &__foot {
          background: #f5f5f5;
          border-radius: 25px;
          display: flex;
          justify-content: space-between;
          width: 732px;
          height: 425px;
          flex-shrink: 0;
          padding: 60px;
          margin-top: 30px;
          &__left {
            &__name {
              color: #141718;
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Poppins;
              font-size: 45.286px;
              font-style: normal;
              font-weight: 500;
            }
            &__toDetail {
              margin-top: 10px;
              color: var(--neutral-07100, #141718);
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Alibaba PuHuiTi 2;
              font-size: 21.311px;
              font-weight: 500;
            }
            &__toDetailLine {
              margin-top: 5px;
              height: 1px;
              width: 110px;
              background-color: #141718;
            }
            &__content {
              color: var(---gray-7, #8c8c8c);
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Alibaba PuHuiTi 2;
              font-size: 16px;
              margin-top: 120px;
            }
          }
          &__right {
            margin-left: 20px;
            img {
              width: 311px;
              height: 291px;
            }
          }
        }

        &__top:hover {
          .photovoltaicContainer-kernel__box__right__top__left__name,
          .photovoltaicContainer-kernel__box__right__top__left__toDetail {
            cursor: pointer;
            background: var(
              --Linear,
              linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            .top__left__toDetailIcon {
              color: #ff5207;
            }
          }
          .photovoltaicContainer-kernel__box__right__top__left__toDetailLine {
            margin-top: 5px;
            height: 1px;
            width: 110px;
            background-color: #ff5207;
          }
        }

        &__foot:hover {
          .photovoltaicContainer-kernel__box__right__foot__left__name,
          .photovoltaicContainer-kernel__box__right__foot__left__toDetail {
            cursor: pointer;
            background: var(
              --Linear,
              linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
            );
            background-clip: text;
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
            .top__left__toDetailIcon {
              color: #ff5207;
            }
          }
          .photovoltaicContainer-kernel__box__right__foot__left__toDetailLine {
            margin-top: 5px;
            height: 1px;
            width: 110px;
            background-color: #ff5207;
          }
        }
      }
    }
  }
  &-product {
    padding-left: 13.3125rem;
    &__title {
      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 6.0625rem;
        height: 1.5rem;
        color: #1c1f35;
        font-size: 0.875rem;
        font-family: Rubik;
        line-height: 1.5rem;
        display: flex;
        justify-content: center;
      }
      &__text {
        color: var(---gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 600;
        margin-top: 1.25rem;
      }
    }
    &__box {
      display: flex;
      flex-wrap: wrap;
      margin-top: 3.75rem;
      &__item {
        cursor: pointer;
        width: 21.875rem;
        height: 37.1875rem;
        margin-bottom: 3.125rem;
        margin-right: 2.0625rem;
        &__top {
          border-radius: 1.5625rem;
          background: #f3f5f7;
          width: 21.875rem;
          height: 28.75rem;
          position: relative;
          padding-top: 1.25rem;
          &__status {
            border-radius: 5.328px;
            background: #fff;
            margin-left: 1.25rem;
            text-align: center;
            width: 5.5rem;
            height: 2rem;
            line-height: 2rem;
            color: var(--neutral-07100, #141718);
            font-family: Inter;
            font-size: 1.3313rem;
            text-transform: uppercase;
            font-weight: 500;
          }
          &__img {
            width: 21.875rem;
            height: 21.875rem;
            margin-top: 0.5rem;
            margin-bottom: 3.4375rem;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &__con {
          margin-top: 0.625rem;
          &__name {
            color: var(--neutral-07100, #141718);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Inter;
            font-size: 1.5rem;
            font-weight: 600;
            margin-left: 1.8rem;
          }
          &__text {
            margin-left: -0.625rem;
            color: var(---gray-7, #8c8c8c);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Alibaba PuHuiTi 2;
            font-size: 1rem;
            font-style: normal;
            margin-top: 1rem;
          }
        }
      }
    }
  }
  &-newProduct {
    height: 709px;
    display: flex;
    margin-top: 30px;

    &__left {
      width: 50%;
      img {
        width: 100%;
        height: 100%;
      }
    }
    &__right {
      padding-top: 200px;
      padding-left: 95px;
      padding-right: 200px;
      width: 50%;
      background: var(--neutral-02100, #f3f5f7);
      &__title {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 100px;
        height: 23px;
        color: #1c1f35;
        font-family: Rubik;
        line-height: 23px;
        display: flex;
        justify-content: center;
      }
      &__text {
        margin-top: 20px;
        color: var(--neutral-07100, #141718);
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-family: Alibaba PuHuiTi 2;
        font-size: 56px;
        font-style: normal;
        font-weight: 500;
      }
      &__con {
        color: var(---gray-8, #595959);
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-family: Alibaba PuHuiTi 2;
        font-size: 26.639px;
        margin-top: 20px;
      }
      &__toDetail {
        margin-top: 50px;
        color: var(--neutral-07100, #141718);
        font-feature-settings:
          'clig' off,
          'liga' off;
        font-family: Alibaba PuHuiTi 2;
        font-size: 21.311px;
        font-style: normal;
        font-weight: 500;
        .newProduct__right__toDetail {
          margin-left: 10pxs;
        }
        .newProduct__right__toDetailLine {
          margin-top: 5px;
          height: 1px;
          width: 110px;
          background-color: #141718;
        }
      }
      &__toDetail:hover {
        cursor: pointer;
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        .newProduct__right__toDetail {
          color: #ff5207;
        }
        .newProduct__right__toDetailLine {
          margin-top: 5px;
          height: 1px;
          width: 110px;
          background-color: #ff5207;
        }
      }
    }
  }
  &-accessories {
    margin-top: 50px;
    margin-left: 210px;
    margin-right: 210px;
    &__title {
      background: rgba(232, 232, 232, 0.5);
      backdrop-filter: blur(20px);
      border-left: 4px solid #ff8629;
      width: 100px;
      height: 23px;
      color: #1c1f35;
      font-family: Rubik;
      line-height: 23px;
      display: flex;
      justify-content: center;
    }
    &__text {
      color: var(---gray-9, #434343);
      font-family: Alibaba PuHuiTi 2;
      font-size: 35px;
      font-style: normal;
      font-weight: 600;
    }

    &__box {
      display: flex;
      justify-content: space-between;
      flex-wrap: wrap;
      margin-top: 80px;
      &__item {
        width: 30%;
        margin-bottom: 50px;
        margin-left: 30px;
        &__top {
          width: 100%;
          height: 402px;
          padding-top: 20px;
          &__img {
            height: 351px;
            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &__con {
          &__name {
            color: var(--neutral-07100, #141718);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Inter;
            font-size: 24px;
            font-weight: 600;
          }
          &__toDetail {
            color: var(--neutral-07100, #141718);
            font-feature-settings:
              'clig' off,
              'liga' off;
            font-family: Alibaba PuHuiTi 2;
            font-size: 21.311px;
            font-style: normal;
            font-weight: 500;
            margin-top: 20px;
            .box__item__con__toDetail {
              margin-left: 10px;
            }
            .box__item__con__toDetailLine {
              margin-top: 5px;
              height: 1px;
              width: 110px;
              background-color: #141718;
            }
          }
        }
      }
      &__item:hover {
        .photovoltaicContainer-accessories__box__item__con__name,
        .photovoltaicContainer-accessories__box__item__con__toDetail {
          cursor: pointer;
          background: var(
            --Linear,
            linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
        }
      }
      &__item:hover {
        .box__item__con__toDetail {
          color: #ff5207;
        }
        .box__item__con__toDetailLine {
          margin-top: 5px;
          height: 1px;
          width: 110px;
          background-color: #ff5207;
        }
      }
    }
  }
  &-line {
    height: 1px;
    background-color: #8c8c8c;
    margin-top: 6.25rem;
    margin-bottom: 6.25rem;
    margin-left: 3.75rem;
    margin-right: 3.75rem;
  }
  .empty {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 18.75rem;
    height: 16rem;
    padding-bottom: 3.125rem;
    margin-left: 50rem;
    img {
      width: 100%;
      height: 100%;
    }
    &_title {
      color: var(---gray-12, #141414);
      font-family: Alibaba PuHuiTi 2;
      font-size: 2rem;
      text-align: center;
      margin-top: 0.9375rem;
    }
    &_text {
      color: var(---gray-8, #595959);
      text-align: center;
      font-family: Alibaba PuHuiTi 2;
      font-size: 1.25rem;
      margin-top: 0.625rem;
    }
  }
}

@root-entry-name: default;
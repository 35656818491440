.productSpeDetailContainer {
  width: 100vw;
  overflow: hidden;
  .inquiryNow {
    height: 3.875rem;
    background: #ff6001 !important;
    color: #fff !important;
    font-size: 34px !important;
    font-weight: bold;
  }
  .productSpeDetailContainer-img__box {
    position: relative;
    button {
      position: absolute;
      top: 58%;
      left: 3%;
      z-index: 999;
    }
  }
  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }
  &-content {
    background: #fff;
    padding-top: 2.81rem;
    padding-left: 3.81rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }
      &__textNav {
        margin-left: 1rem;
        color: #637381;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        cursor: pointer;
      }
      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
  }
  &-desc {
    width: 100%;
    // display: flex;
    // align-items: center;
    // justify-content: center;
    margin-top: 50px;
  }
  &-img {
    margin-top: 50px;
    &__box {
      width: 100vw;
      height: 100%;
      img {
        width: 100%;
        height: 100%;
      }
    }
  }
  &-swiper {
    background-color: #fff;
    padding: 6.25rem 4.0625rem;
    &__title {
      display: flex;
      flex-direction: column;
      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 10rem;
        height: 1.4375rem;
        color: #1c1f35;
        font-family: Rubik;
        line-height: 1.4375rem;
        display: flex;
        font-size: 0.875rem;
        justify-content: center;
      }
      &__text {
        color: var(---gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 600;
        margin-top: 1rem;
      }
    }
    &__box {
      display: flex;
      margin-bottom: 4.375rem;
      padding: 3.75rem;
      padding-left: 3.125rem;
      &__left {
        width: 45%;
        &__item {
          padding: 1.875rem 7.75rem 1.875rem 5.75rem;

          &__img {
            width: 31.25rem;
            height: 31.25rem;

            img {
              width: 100%;
              height: 100%;
            }
          }
        }
        &__dots {
          display: flex;
          margin-top: 1.875rem;
          margin-left: 8rem;
          cursor: pointer;
          .left__item__dots {
            width: 5rem;
            height: 0.625rem;
            background-color: #bfbfbf;
            border-radius: 1.25rem;
            margin-left: 0.625rem;
            margin-right: 0.625rem;
          }
          .left__item__dotsNot {
            margin-left: 0.625rem;
            width: 2.5rem;
            height: 0.625rem;
            background-color: #e5e5e5;
            border-radius: 1.25rem;
          }
        }
      }
      &__right {
        position: relative;
        &__prev {
          position: absolute;
          left: -6rem;
          top: 50%;
          width: 3.75rem;
          height: 3.75rem;
          cursor: pointer;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &__img {
          position: absolute;
          overflow: hidden;
          width: 56.9375rem;
          padding: 1.25rem;

          img {
            width: 100%;
            height: 100%;
          }
        }
        &__next {
          cursor: pointer;
          position: absolute;
          left: 59rem;
          top: 50%;
          width: 3.75rem;
          height: 3.75rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
      }
    }
    &__downLoad {
      display: flex;
      justify-content: space-around;
      margin-left: 10%;
      margin-right: 10%;
      margin-top: 20px;
      &__item {
        display: flex;
        color: #153a5b;
        font-family: Alibaba PuHuiTi 2;
        font-size: 23px;
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 20px;
        &__img {
          cursor: pointer;
          width: 2.25rem;
          height: 1.875rem;
          margin-left: 10px;
        }
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  &-bottomImg {
    width: 100vw;
  }
  &-bottom {
    margin-top: 3.125rem;
    &__title {
      display: flex;
      flex-direction: column;
      align-items: center;
      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 8.75rem;
        height: 1.5625rem;
        color: #1c1f35;
        font-size: 0.875rem;
        font-family: Rubik;
        line-height: 1.5625rem;
        display: flex;
        justify-content: center;
      }
      &__text {
        color: var(---gray-9, #434343);
        font-family: Alibaba PuHuiTi 2;
        font-size: 2.1875rem;
        font-style: normal;
        font-weight: 600;
        margin-top: 0.625rem;
      }
    }
    &__box {
      display: flex;
      justify-content: center;
      margin-top: 3.125rem;
      margin-bottom: 0.1953rem;
      position: relative;
      .leftIcon {
        cursor: pointer;
        position: absolute;
        width: 22px;
        height: 38px;
        left: -30px;
        top: 45%;
      }
      .rightIcon {
        cursor: pointer;
        position: absolute;
        width: 22px;
        height: 38px;
        right: -30px;
        top: 45%;
      }
      &__item {
        border-radius: 1.5625rem;
        background: #f5f5f5;
        width: 28.375rem;
        height: 33.75rem;
        margin-left: 1.25rem;
        margin-right: 4.125rem;
        &__title {
          margin: 1.25rem 1.25rem 0 1.25rem;
          border-radius: 1rem;
          width: 25.875rem;
          height: 23.75rem;
          img {
            width: 100%;
            height: 100%;
          }
        }
        &__name {
          margin-left: 1.875rem;
          margin-right: 0.625rem;
          padding-bottom: 0.3125rem;
          color: #000;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.3rem;
          border-bottom: 1px solid #d6d6d6;
        }
        &__text {
          color: #a7a7a7;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1rem;
          margin-right: 0.625rem;
          margin-top: 0.625rem;
        }
      }
      &__item:hover {
        cursor: pointer;
        .productSpeDetailContainer-bottom__box__item__name {
          margin-left: 1.875rem;
          margin-right: 0.625rem;
          background: var(
            --Linear,
            linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 1px solid #d6d6d6;
        }
      }
    }
  }

  &-line {
    height: 1px;
    background-color: #d6d6d6;
    margin-top: 5rem;
    margin-bottom: 5rem;
    margin-left: 3.125rem;
    margin-right: 3.125rem;
  }
}

@root-entry-name: default;
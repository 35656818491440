.downLoadCenter__box {
  margin-top: 5rem;
}
.downLoadCenter__box__contanier {
  display: flex;
  margin-top: 1.875rem;
  &__left {
    width: 20%;
    .ant-menu-item:hover,
    .ant-menu-item-active:hover,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-active,
    .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-title:hover {
      color: #333;
    }
    .ant-menu-item-selected {
      color: #333;
      background-color: white !important;
    }
    .ant-menu-item-selected::after {
      right: 0.1875rem;
      opacity: 0;
    }

    .ant-menu-item-selected,
    .ant-menu-item-selected:hover {
      color: #333;
      background-color: white;
    }
    .ant-menu-submenu-title {
      background-color: #f9f9f9;
      margin-top: -0.25rem;
      .ant-menu-title-content {
        font-size: 0.875rem;
        font-weight: 600;
        color: #333;
      }
    }
    .ant-menu-sub {
      margin-top: -0.25rem;
      background-color: white;
    }
    .ant-menu-item {
      padding-left: 1.5625rem !important;
    }
  }
  &__right {
    margin-left: 1.25rem;
    width: 80%;

    &__table {
      display: flex;
      justify-content: space-between;
      margin-bottom: 1.175rem;
      border-bottom: 1px solid rgba(240, 240, 240, 1);
      padding-bottom: 1.25rem;
      &__icon {
        width: 1.875rem;
        height: 1.875rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      &__left {
        margin-top: 0.3125rem;
        margin-left: 0.625rem;
        font-size: 0.875rem;
        color: rgba(31, 31, 31);
      }
      &__right {
        border: 1px solid rgba(0, 0, 0, 0.12);
        padding: 0.625rem;

        color: #333;
        font-size: 700;
        height: 2rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        line-height: 2rem;
        border-radius: 0.25rem;
        cursor: pointer;
      }
      &__right:hover {
        padding: 0.625rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
        font-size: 700;
        height: 2rem;

        border-radius: 0.25rem;
        cursor: pointer;

        background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        border: 1px solid #ffa800;
      }
    }
    .empty {
      margin-left: 30%;
      width: 18.75rem;
      height: 25.875rem;
      .empty_img {
        width: 18.75rem;
        height: 18.75rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
      .empty_title {
        font-size: 2rem;
        font-weight: 400;
      }
      .empty_text {
        font-size: 1.25rem;
        margin-top: 0.625rem;
      }
    }
  }
}
.ant-checkbox-checked .ant-checkbox-inner {
  background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
  border: 0;
}
.ant-checkbox-checked .ant-checkbox-inner:hover {
  background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
  border: 0;
}

.ant-checkbox-wrapper:hover .ant-checkbox-inner {
  border-color: #ffa800 !important;
}

.ant-menu-light .ant-menu-submenu-active {
  color: #ffa800;
}
.ant-menu-light .ant-menu-submenu-title:hover {
  color: #ffa800;
}
.ant-menu-light .ant-menu-submenu:hover {
  .ant-menu-submenu-title {
    .ant-menu-submenu-arrow {
      color: #ffa800;
    }
  }
}

@root-entry-name: default;
.newsDetailContainer {
  width: 100vw;

  &-content {
    background: #fff;
    padding-top: 2.81rem;
    padding-left: 3.81rem;
    &__title {
      display: flex;
      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }
      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }
      &__textNav {
        margin-left: 1rem;
        color: #637381;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        cursor: pointer;
      }

      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }
    &__center {
      margin-left: 22rem;
      margin-right: 22rem;
      margin-top: 1.875rem;
      &__title {
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 20px;
        &__top {
          color: #1c1f35;
          font-family: Alibaba PuHuiTi 2;
          font-size: 2.6rem;
        }
        &__time {
          display: flex;
          margin-top: 10px;
          .newCalendar {
            width: 1.5rem;
            height: 1.5rem;
            margin-top: 0.1875rem;
          }
          .newCalendarTime {
            color: #9a9a9a;
            font-family: Poppins;
            font-size: 1.1rem;
            margin-left: 0.625rem;
          }
        }
      }
      &__top {
        color: #595959;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1.1rem;
        margin-top: 1.875rem;
      }
      // &__img {
      //   width: 37.5rem;
      //   height: 25rem;
      //   margin-left: 15%;
      //   margin-top: 1.875rem;
      // }
      &__rich {
        margin-top: 1.875rem;
        border-bottom: 1px solid #d6d6d6;
        padding-bottom: 1.25rem;
      }
      &__foot {
        margin-top: 1.875rem;
        margin-bottom: 3.125rem;
        display: flex;
        justify-content: space-between;
        color: #949494;
        font-family: Alibaba PuHuiTi 2;
        font-size: 0.75rem;
        &__prev {
          cursor: pointer;
          max-width: 30rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
        &__next {
          cursor: pointer;
          max-width: 30rem;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }
  }
}

@root-entry-name: default;
.projectCaseDetail__tiltle {
  display: flex;
  justify-content: space-between;
  text-align: left;
  align-items: center;
  background-color: rgba(245, 245, 245, 1);
  width: 74.375rem;
  height: 7.625rem;
  border-radius: 0.75rem;
  margin-left: 20rem;
  margin-top: 3.125rem;
  padding-left: 1.875rem;
  padding-right: 1.875rem;
  margin-bottom: 3.125rem;
  &__box {
    &__text {
      font-size: 1rem;
      color: rgba(0, 0, 0, 1);
    }
    &__value {
      margin-top: 0.625rem;
      font-size: 1.75rem;
      font-weight: 600;
      background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
    }
  }
}
.projectCaseDetail__center {
  display: flex;
  text-align: left;
  margin-left: 20rem;

  margin-bottom: 4rem;
  position: relative;

  &__left {
    width: 45.75rem;
    height: 34.3125rem;
    img {
      width: 100%;
      height: 100%;
      border-radius: 1.5625rem;
    }
    video {
      width: 100%;
      height: 100%;
      border-radius: 1.5625rem;
    }
  }
  &__right {
    width: 26.625rem;
    // height: 120%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    margin-left: 2.5rem;
    &__title {
      color: rgba(28, 31, 53, 1);
      font-size: 2.25rem;
      font-weight: 400;
      line-height: 2.75rem;
    }
    &__foot {
      width: 26.625rem;
      color: rgba(89, 89, 89, 1);
      font-size: 1.125rem;
      position: absolute;
      bottom: 0;
      line-height: 1.525rem;
    }
  }
}
.projectCaseDetail__line {
  width: 74.4375rem;
  height: 1px;
  background-color: rgba(214, 214, 214, 1);
  margin-left: 20rem;
  margin-bottom: 5rem;
}
.empty {
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 18.75rem;
  height: 25.875rem;
  text-align: center;
  margin-left: 45rem;
  margin-top: 3.125rem;
  margin-bottom: 3.125rem;

  .empty_img {
    width: 18.75rem;
    height: 18.75rem;
    img {
      width: 100%;
      height: 100%;
    }
  }
  .empty_title {
    font-size: 2rem;
  }
  .empty_text {
    font-size: 1.25rem;
  }
}

@root-entry-name: default;
.projectCase_box {
  display: flex;
  margin-top: 3.125rem;
  margin-left: 15%;
  flex-wrap: wrap;
  cursor: pointer;
  &__item {
    width: 25rem;
    // height: 24.875rem;
    margin-right: 1.875rem;
    margin-bottom: 1.875rem;
    &__top {
      position: relative;
      &__img {
        width: 25rem;
        height: 18.75rem;

        img {
          width: 100%;
          height: 100%;
          border-radius: 0.625rem;
        }
      }
      &__text {
        position: absolute;
        top: 0.9375rem;
        left: 0.9375rem;
        display: flex;
        &__left {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.2);
          padding: 0.1875rem;
          border-radius: 0.25rem;
          font-size: 0.875rem;
        }
        &__right {
          color: rgba(255, 255, 255, 1);
          background-color: rgba(255, 255, 255, 0.2);
          padding: 0.1875rem;
          border-radius: 0.25rem;
          margin-left: 0.625rem;
          font-size: 0.875rem;
        }
      }
    }
    &__name {
      margin-top: 1.25rem;
      font-size: 1.5rem;
      color: rgba(18, 20, 22, 1);
    }
    &__detail {
      width: 5rem;
      margin-top: 1rem;
      border-bottom: 1px solid rgba(20, 20, 20, 1);
      font-size: 1.125rem;
    }
  }
  .projectCase_box__item:hover {
    .projectCase_box__item__name {
      margin-top: 1.25rem;
      font-size: 1.5rem;
      color: #ed6e2d;
    }
    .projectCase_box__item__detail {
      width: 5rem;
      margin-top: 1rem;
      background: var(--3, linear-gradient(259deg, #ffa800 0%, #ff5207 100%));
      background-clip: text;
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      border-bottom: 1px solid #ed6e2d;
      font-size: 1.125rem;
    }
  }
}
.projectCase_Pagination {
  display: flex;
  justify-content: center;
  margin-top: 3.125rem;
}

@root-entry-name: default;
@import '~@/style/vars.less';

.@{layout} {
  .@{layout}-header-wrapper {
    width: 100%;
    position: sticky;
    top: 0;
    z-index: 99999;
    .mask {
      width: 100%;
      height: 100%;
      position: absolute;
      top: 0;
      left: 0;
      z-index: 199;
      box-shadow: 1px 1px 5px #d9d9d9;
    }

    .@{layout}-header {
      width: 100%;
      height: 6.25rem;
      display: flex;
      align-items: center;
      justify-content: space-between;
      text-indent: 20px;
      position: sticky;
      position: -webkit-sticky;
      top: 0;
      z-index: 999;
      background-color: #ffffff;
      position: relative;
      &-left {
        display: flex;

        &-icon {
          margin-left: 3.75rem;
          width: 13.0625rem;
          height: 3.875rem;
          cursor: pointer;
        }
      }

      &-right {
        height: 100%;
        display: flex;
        align-items: center;

        &-menu {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: space-between;

          > div {
            height: 100%;
            display: flex;
            align-items: center;
          }

          &-item {
            display: flex;
            align-items: center;
            justify-content: center;
            text-align: center;
            color: var(--gray-10, #262626);
            font-family: Alibaba PuHuiTi 2;
            font-size: 1.25rem;
            font-style: normal;
            font-weight: 400;
            min-width: 6.25rem;
            cursor: pointer;
            margin-right: 10px;
            height: 100%;
            position: relative;
          }

          &-cur {
            width: 100%;
            height: 3px;
            background: var(
              --4,
              linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%)
            );
            position: absolute;
            bottom: 1.2rem;
            left: 60%;
            transform: translateX(-50%);
            border-radius: 20px;
          }
        }

        &-btn {
          margin-left: 4rem;
          margin-right: 3.75rem;
          width: 6.25rem;
          height: 3.125rem;
          border-radius: 2.5rem;
          background: var(--gray-5, #d9d9d9);
          box-shadow: 0px 0px 13.334px 1.333px rgba(255, 255, 255, 0.5);
          color: var(--fore-allways-white, #fff);
          display: flex;
          text-indent: 0;

          &-left {
            border-radius: 50%;
            width: 3.125rem;
            height: 3.125rem;
            background-color: rgba(0, 0, 0, 0.5);
            font-family: Roboto;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }

          &-leftDefult {
            color: var(--fore-allways-white, #fff);
            font-family: Roboto;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            width: 3.125rem;
            height: 3.125rem;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }

          &-right {
            border-radius: 50%;
            width: 3.125rem;
            height: 3.125rem;
            background-color: rgba(0, 0, 0, 0.5);
            font-family: Roboto;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }

          &-rightDefult {
            color: var(--fore-allways-white, #fff);
            font-family: Roboto;
            font-size: 1.125rem;
            font-style: normal;
            font-weight: 400;
            cursor: pointer;
            width: 3.125rem;
            height: 3.125rem;
            display: flex;
            justify-content: center;
            text-align: center;
            align-items: center;
          }
        }
      }
    }
  }

  .@{layout}-content {
    flex: 1;
    display: flex;
    flex-direction: row;
    overflow: hidden;
  }

  .@{layout}-main {
    flex: 1;
  }

  .@{layout}-bottom {
    &-center {
      height: 31.25rem;
      background: var(--gray-12, #141414);
      display: flex;

      &__left {
        width: 30%;
        padding: 5.375rem 0 0 8%;

        &__logo {
          background-image: url(../assets/image/bottomLogo.png);
          background-size: 100% 100%;
          background-repeat: no-repeat;
          width: 13.0625rem;
          height: 3.875rem;
          margin-bottom: 2.75rem;
        }

        &__item {
          display: flex;
          margin-bottom: 1.5625rem;
          &__icon {
            width: 3.75rem;
            height: 3.75rem;
            flex: none;
            background-size: 100% 100%;
            background-repeat: no-repeat;
          }

          &__value {
            margin-left: 1.0625rem;
            margin-top: 0.625rem;

            .value_title {
              color: var(--fore-allways-white, #fff);
              font-family: Alibaba PuHuiTi 2;
              font-size: 0.875rem;
              font-weight: 500;
            }

            .value_con {
              color: var(--fore-allways-white, #fff);
              font-family: Alibaba PuHuiTi 2;
              font-size: 0.8125rem;
              white-space: break-spaces;
            }
          }
        }
      }

      &__right {
        display: flex;
        justify-content: space-around;
        padding-top: 5.375rem;
        width: 60%;
        margin-left: 5%;
        &__item {
          &__title {
            color: var(--fore-allways-white, #fff);
            font-family: Alibaba PuHuiTi 2;
            font-size: 1.25rem;
            font-weight: 500;
          }

          &__list {
            margin-top: 1.25rem;

            .vsf-layout-bottom-center__right__item__listItem {
              color: var(--fore-allways-white, #fff);
              font-family: Alibaba PuHuiTi 2;
              font-size: 1rem;
              line-height: 2.5rem;
              cursor: pointer;
            }
          }
        }
      }
    }

    &-footer {
      background: var(--gray-12, #141414);
      border-top: 1px solid rgba(255, 255, 255, 0.8);
      display: flex;
      height: 4.9375rem;
      justify-content: space-between;
      align-items: center;

      &__left {
        color: var(--gray-7, #8c8c8c);
        font-family: Alibaba PuHuiTi 2;
        font-size: 0.875rem;
        padding: 0 0 0 8%;
      }

      &__right {
        display: flex;
        margin-right: 10%;
        div {
          margin-left: 0.625rem;
          cursor: pointer;
        }
      }

      .vsf-layout-bottom-center__right__item__listItemImg {
        width: 1.5rem;
        height: 1.5rem;
        margin-left: 1.25rem;
        img {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
  .moveItme {
    text-align: center;
  }
  .vsf-accept-cookie {
    bottom: 40px;
    left: 40px;
    position: fixed;
    width: 440px;
    box-sizing: border-box;
    z-index: 9999999;
    border-radius: 6px;
    .vsf-accept-wrapper {
      background: #ffffff;
      border: 1px solid;
      padding: 20px 26px;
      box-shadow: 0 -1px 10px 0 #acabab4d;
      border-radius: 6px;
      border-color: #f4f4f4;
      .vsf-accept-header {
        color: #212121;
        font-weight: 700;
        font-size: 18px;
        line-height: 24px;
        margin: 0 0 12px 0;
        word-break: break-word;
      }
      .vsf-accept-content {
        color: #212121;
        font-size: 14px;
        line-height: 24px;
        font-weight: 400;
        .vsf-notice-btn-wrapper {
          display: flex;
          justify-content: flex-start;
          align-items: center;
          flex-wrap: wrap;
          gap: 8px;
          margin-top: 16px;
          .vsf-notice-btn {
            outline: none;
            flex: auto;
            max-width: 100%;
            font-size: 14px;
            font-family: inherit;
            line-height: 24px;
            padding: 8px;
            font-weight: 500;
            border-radius: 2px;
            cursor: pointer;
            text-align: center;
            text-transform: none;
            min-height: 0;
            overflow-wrap: break-word;
            text-shadow: none;
            box-shadow: none;
            color: #ffffff;
            border: 2px solid #ff6001;
            background-color: #ff6001;
            &.normal {
              background: transparent;
              color: #ff6001;
            }
          }
        }
      }
    }
  }
}
.ant-message-success .anticon {
  color: #ffa800 !important;
}
.ant-message {
  z-index: 999999 !important;
  top: 50% !important;
  transform: translateY(-50%) !important;
}

@root-entry-name: default;
// 滚动条整体宽度
::-webkit-scrollbar {
  width: 8px;
  height: 8px;
  display: none;
}

// 滚动条滑槽样式
::-webkit-scrollbar-track {
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.3);
  border-radius: 8px;
}

// 滚动条样式
::-webkit-scrollbar-thumb {
  border-radius: 8px;
  background: #ddd;
  // -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-corner {
  background-color: transparent;
}

ul,
ol,
dl {
  list-style: none;
}

.ant-btn-primary {
  border-radius: 1.875rem !important;
  background: #fff !important;
  display: inline-flex !important;
  padding: 1.25rem 4.375rem !important;
  height: 4rem !important;
  justify-content: center !important;
  align-items: center !important;
  gap: 0.625rem !important;
  border-color: transparent !important;
  color: #23212a !important;
  font-family: Alibaba PuHuiTi 2 !important;
  font-size: 1rem !important;
}

.customInput::placeholder {
  color: #fff !important;
}

.customInput:focus {
  color: #fff !important;
  border: 1px solid var(--fore-allways---white, #fff) !important;
}

.customTextArea {
  width: 55.8rem !important;
  height: 9rem !important;
  border-radius: 1.875rem !important;
  border: 1px solid var(--fore-allways---white, #fff) !important;
  background-color: transparent !important;
  color: #fff !important;
  padding-top: 1rem !important;
}

.customTextArea::placeholder {
  color: #fff !important;
}

.customInput {
  height: 3.75rem !important;
  // width: 26.7rem !important;
  border-radius: 2.625rem !important;
  background-color: transparent !important;
  border: 1px solid var(--fore-allways---white, #fff) !important;
  color: #fff !important;
}

.ant-select.ant-select-in-form-item {
  border-radius: 2.625rem !important;
  background-color: transparent !important;
  color: #fff !important;
  // width: 26.7rem !important;
}

.ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-radius: 2.625rem !important;
  background-color: transparent !important;
  border: 1px solid var(--fore-allways---white, #fff) !important;
  color: #fff !important;
  height: 3.75rem !important;
  // width: 26.7rem !important;
}

.ant-select-single.ant-select-show-arrow .ant-select-selection-item,
.ant-select-single.ant-select-show-arrow .ant-select-selection-placeholder {
  color: #fff !important;
  line-height: 4rem !important;
}

.ant-select-arrow {
  color: #fff !important;
}

.customTable .ant-table-thead > tr > th {
  background-color: #fff !important;
  color: var(---gray-10, #262626);
  font-family: Alibaba PuHuiTi 2;
  font-size: 1.25rem;
  font-weight: 700;
}

.name {
  color: #fc7300;
  font-family: Alibaba PuHuiTi 2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.action {
  color: var(---gray-9, #434343);
  font-family: Alibaba PuHuiTi 2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 700;
  cursor: pointer;
}

.tableItem {
  color: var(---gray-7, #8c8c8c);
  font-family: Alibaba PuHuiTi 2;
  font-size: 1rem;
  font-style: normal;
  font-weight: 500;
  cursor: pointer;
}

.slick-dots li button {
  width: 12px !important;
  height: 12px !important;
  background: #fff !important;
  border-radius: 100% !important;
}

.slick-dots li {
  width: 23px !important;
}

.slick-dots li.slick-active {
  width: 23px !important;
}

.ant-carousel-vertical .slick-dots-right {
  right: 30px !important;
}

.ant-form-item .ant-form-item-has-success {
  background: transparent !important;
}

.ant-input .ant-input-status-success .customInput {
  background: transparent !important;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: #fff !important;
}

.Arrowdown {
  width: 26px;
  height: 26px;
}

.modalContainer_move {
  width: 77%;
  min-width: 1240px;
  display: none;
  animation: product_move 0.5s ease-in-out forwards;
  position: absolute;
  top: 100%;
  left: 50%;
  z-index: 99;
  background-color: #fff;
  transform: translateX(-50%);
  .btn {
    cursor: pointer;
    text-align: center;
  }
}

.modalContainer {
  width: 100%;
  // height: 28.75rem;
  padding: 1.875rem;
  padding-top: 3.125rem;
  overflow: hidden;
  display: flex;

  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    display: block;
  }

  &-left {
    width: 45%;

    &__top {
      display: flex;
      align-items: center;
      transform: translateY(-25px);

      &__text {
        color: var(---, #393e46);
        font-family: Alibaba PuHuiTi 2;
        font-size: 1.25rem;
        // margin-left: 0.625rem;
      }

      &__dots {
        width: 0.4375rem;
        height: 0.4375rem;
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
        border-radius: 50%;
      }
    }

    &__middle {
      color: var(---, #393e46);
      font-family: Poppins;
      font-size: 3rem;
      margin-left: 0.625rem;
    }

    &__box {
      margin-top: 6.25rem;
      display: flex;
      flex-wrap: wrap;

      &__item-background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: -1;
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
      }

      &__item {
        cursor: pointer;
        display: flex;
        width: 14.125rem;
        height: 3.5rem;
        line-height: 3.5rem;
        margin-left: 0.625rem;
        margin-bottom: 0.625rem;
        border-radius: 3.125rem;
        text-align: center;
        position: relative;
        overflow: hidden;
        padding-left: 1.5rem;
      }

      .itemDots {
        width: 0.4375rem;
        height: 0.4375rem;
        background: #fff;
        border-radius: 50%;
        margin-top: 1.5625rem;
      }

      .itemName {
        color: var(--fore-allways---white, #fff);
        font-family: Alibaba PuHuiTi 2;
        margin-left: 0.625rem;
      }

      &__itemNot {
        width: 14.125rem;
        display: flex;
        cursor: pointer;
        height: 3.5rem;
        line-height: 3.5rem;
        margin-left: 0.625rem;
        border: 1px solid #bfbfbf;
        margin-bottom: 0.625rem;
        border-radius: 3.125rem;
        text-align: center;
        transition: all 0.3s linear;
        position: relative;
        overflow: hidden;
        padding-left: 1.5rem;
      }

      &__itemNot-background {
        width: 100%;
        height: 100%;
        position: absolute;
        left: 0;
        top: 0;
        z-index: 100;
        background-color: #fff;
        opacity: 0;
      }

      &__itemNot:hover &__itemNot-background {
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
        opacity: 1;
        transition: opacity linear 0.33s;
      }

      &__itemNot:hover .itemDotsNot {
        background: #fff !important;
      }

      &__itemNot:hover .itemNameNot {
        color: #fff;
      }

      .itemDotsNot {
        width: 0.4375rem;
        height: 0.4375rem;
        background: var(
          --Linear,
          linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
        );
        border-radius: 50%;
        margin-top: 1.5625rem;
        transition: all 0.3s linear;
        position: relative;
        z-index: 999;
      }

      .itemNameNot {
        margin-left: 0.625rem;
        color: var(---, #393e46);
        font-family: Alibaba PuHuiTi 2;
        transition: all 0.3s linear;
        position: relative;
        z-index: 999;
      }
    }
  }

  &-right {
    width: 60%;
    border-left: 1px solid #d9d9d9;
    padding-left: 1.875rem;
    height: 100%;
    overflow-y: scroll;

    &__box {
      &__title {
        display: flex;

        &__icon {
          width: 2rem;
          height: 2rem;
        }

        &__text {
          color: #0f0f0f;
          font-family: Alibaba PuHuiTi 2;
          font-size: 1.25rem;
          margin-left: 0.625rem;
        }
      }

      &__imgBox {
        cursor: pointer;
        margin-top: 1.875rem;
        width: 11.875rem;
        height: 6.25rem;
        img {
          width: 100%;
          height: 100%;
        }
      }

      &__imgRightBox {
        cursor: pointer;
        margin-top: 1.875rem;
        width: 24.375rem;
        height: 18.3125rem;

        img {
          width: 100%;
          height: 100%;
        }
      }
    }

    &__speBox {
      &__item {
        margin-bottom: 1.875rem;

        &__title {
          display: flex;

          &__icon {
            width: 1.75rem;
            height: 1.75rem;
          }

          &__text {
            color: #0f0f0f;
            font-family: Alibaba PuHuiTi 2;
            font-size: 1.25rem;
            margin-left: 0.625rem;
          }
        }

        &__con {
          display: flex;
          margin-top: 0.9375rem;
          cursor: pointer;
          &__box {
            &__item {
              margin-right: 1.25rem;
              padding: 0.3125rem 3.125rem 0.3125rem 3.125rem;
              width: 11.875rem;
              height: 6.25rem;
              background-color: #eeeeee;
              border-radius: 0.625rem;
              &__imgBox {
                width: 5.6547rem;
                height: 5.625rem;
                img {
                  width: 100%;
                  height: 100%;
                }
              }

              &__name {
                text-align: center;
                color: var(---, #393e46);
                font-family: Alibaba PuHuiTi 2;
                font-size: 0.875rem;
                cursor: pointer;
                margin-top: 0.625rem;
              }
            }
          }
          &__box:hover {
            cursor: pointer;
            .modalContainer-right__speBox__item__con__box__item__name {
              font-size: 0.875rem;
              background: var(
                --Linear,
                linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
              );
              background-clip: text;
              -webkit-background-clip: text;
              -webkit-text-fill-color: transparent;
              font-family: Alibaba PuHuiTi 2;
            }
          }
        }
      }
    }
  }
}

.modalContainer_moveSer {
  width: 5%;
  min-width: 8rem;
  display: none;
  animation: product_move 0.5s ease-in-out forwards;
  position: absolute;
  left: 74.5%;
  z-index: 99;
  background-color: #fff;
  transform: translateX(-50%);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  .moveSerBox {
    padding: 1.875rem 1.875rem 0 1.875rem;
    padding-top: 1.125rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .moveItme {
      color: #262626;
      font-size: 1rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
    .moveItme:hover {
      color: #fc7300;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
}

.modalContainer_moveAbo {
  width: 5%;
  min-width: 8rem;
  display: none;
  animation: product_move 0.5s ease-in-out forwards;
  position: absolute;
  left: 80.5%;
  z-index: 99;
  background-color: #fff;
  transform: translateX(-50%);
  border-bottom-left-radius: 0.375rem;
  border-bottom-right-radius: 0.375rem;
  .moveSerBox {
    padding: 1.875rem 1.875rem 0 1.875rem;
    padding-top: 1.125rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;
    .moveItme {
      color: #262626;
      font-size: 1rem;
      margin-bottom: 1rem;
      cursor: pointer;
    }
    .moveItme:hover {
      color: #fc7300;
      margin-bottom: 1rem;
      cursor: pointer;
    }
  }
}

.isUrgentText {
  width: 3.125rem;
  height: 1.25rem;
  flex-shrink: 0;
  border-radius: 0.625rem;
  background: #ffe0e0;
  display: flex;
  justify-content: center;
  text-align: center;
  align-items: center;
  margin-top: 3px;
  margin-left: 0.625rem;

  .isUrgentText__icon {
    width: 0.375rem;
    height: 0.375rem;
    background-color: #d30000;
    border-radius: 50%;
  }

  .isUrgentText__text {
    color: var(--Fonts-Negative, #d30000);
    font-family: Alibaba PuHuiTi 2;
    font-size: 0.75rem;
    margin-left: 3px;
  }
}

.ant-table-cell {
  height: 5rem;
}

.my-pagination {
  .ant-pagination {
    font-size: 0.875rem !important;
  }
  .ant-pagination-item {
    background: #fff;
    border-radius: 0.5rem;
    border: 1px solid #f1f1f1;
  }

  .ant-pagination-disabled .ant-pagination-item-link,
  .ant-pagination-disabled:hover .ant-pagination-item-link {
    border-radius: 0.5rem;
    border: 1px solid #f1f1f1;
    background: #fff;
  }

  .ant-pagination-prev .ant-pagination-item-link,
  .ant-pagination-next .ant-pagination-item-link {
    border-radius: 0.5rem;
    border: 1px solid #f1f1f1;
    background: #fff;
  }

  .ant-pagination-item-active a {
    color: #fff;
  }

  .ant-pagination-item-active a:hover {
    color: #fff;
  }

  .ant-pagination-item-active a:focus {
    color: #fff;
  }

  .ant-pagination-item-active {
    background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
    border: 0;
    border-radius: 0.5rem;
    color: #fff;
  }
}

@keyframes product_move {
  0% {
    top: -100%;
  }

  100% {
    top: 100%;
  }
}

.ant-popover-inner {
  border-radius: 4.5px !important;
}

.ant-popover-title {
  display: flex;
  justify-content: center;
  padding: 0 !important;
}

.ant-popover-inner-content {
  height: 1.5rem;
  line-height: 0.2rem;
  padding-left: 32% !important;
  background-color: #d9d9d9;
  width: 100% !important;
  color: #5d5d5d !important;
  font-family: Alibaba PuHuiTi 2;
  font-size: 12px;
  font-weight: 500;
  border-bottom-left-radius: 4.5px;
  border-bottom-right-radius: 4.5px;
}

@root-entry-name: default;
.newsContainer {
  width: 100vw;

  &-titleImg {
    img {
      width: 100%;
      height: 100%;
    }
  }

  &-content {
    background: #fff;
    padding-top: 2.81rem;
    padding-left: 3.81rem;

    &__title {
      display: flex;

      &__home {
        width: 1.125rem;
        height: 1.125rem;
        margin-top: 0.1rem;
      }

      &__group {
        width: 0.47725rem;
        height: 0.9rem;
        margin-left: 1.2rem;
        margin-top: 0.3rem;
      }

      &__text {
        margin-left: 1rem;
        font-family: Alibaba PuHuiTi 2;
        font-size: 1rem;
        font-style: normal;
        line-height: 1.5rem;
        background: var(--4, linear-gradient(101deg, #fe9c29 0%, #ff8a01 100%));
        background-clip: text;
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
      }
    }

    &__center {
      padding: 3.75rem 21.875rem 3.75rem 21.875rem;
      &__top {
        background: rgba(232, 232, 232, 0.5);
        backdrop-filter: blur(20px);
        border-left: 4px solid #ff8629;
        width: 6rem;
        height: 1.4375rem;
        font-size: 0.875rem;
        color: #1c1f35;
        font-family: Rubik;
        line-height: 1.4375rem;
        display: flex;
        justify-content: center;
      }

      &__flex {
        display: flex;
        justify-content: space-between;

        &__left {
          color: var(---gray-9, #434343);
          text-align: left;
          font-family: Alibaba PuHuiTi 2;
          font-size: 2.1875rem;
          font-weight: 600;
          margin-top: 1rem;
          white-space: break-spaces;
        }

        &__right {
          border-radius: 0.5rem;
          border: 1px solid #e8e8e8;
          background: #fafafa;
          box-shadow: 0px 4px 12px 0px rgba(13, 10, 44, 0.06);
          display: flex;
          align-items: center;
          margin-top: 1rem;
          height: 3.5rem;
          line-height: 3.5rem;
          cursor: pointer;

          &__new {
            color: #667085;
            font-family: Alibaba PuHuiTi 2;
            padding: 0.625rem 1.25rem;
            font-size: 1rem;
            font-weight: 400;
          }

          &__newChoose {
            color: #ffffff;
            height: 2.5rem;
            padding: 0 1rem;
            font-size: 1rem;
            display: flex;
            margin-left: 1rem;
            line-height: 2.5rem;
            justify-content: center;
            border-radius: 0.375rem;
            background: var(
              --Linear,
              linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
            );
            box-shadow:
              0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1);
            white-space: nowrap;
          }

          &__dynamic {
            color: #667085;
            font-family: Alibaba PuHuiTi 2;
            padding: 0.625rem 1.25rem;
            font-size: 1rem;
            text-align: center;
            white-space: nowrap;
          }

          &__dynamicChoose {
            height: 2.5rem;
            // width: 6rem;
            font-size: 1rem;
            padding: 0 1rem;
            margin-right: 1rem;
            line-height: 2.5rem;

            white-space: nowrap;
            display: flex;
            justify-content: center;
            color: #ffffff;
            border-radius: 0.375rem;
            background: var(
              --Linear,
              linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
            );
            box-shadow:
              0px 1px 2px 0px rgba(16, 24, 40, 0.06),
              0px 1px 3px 0px rgba(16, 24, 40, 0.1);
          }
        }
      }

      &__box {
        &__item {
          display: flex;
          border-top: 1px solid #d6d6d6;
          margin-top: 2rem;
          padding-top: 2rem;
          margin-bottom: 5rem;

          &__left {
            display: flex;
            border-right: 1px solid #d6d6d6;
            width: 50%;
            height: 19.25rem;
            &__img {
              width: 100%;
              height: 19.25rem;
              img {
                width: 100%;
                height: 100%;
              }
            }

            &__month {
              margin-left: 2rem;
              margin-right: 2rem;
              display: flex;
              flex-direction: column;
              align-items: center;
              &__calanderIcon {
                width: 3.2rem;
                height: 3.5rem;
                img {
                  width: 100%;
                  height: 100%;
                }
              }
              &__text {
                color: #1c1f35;
                text-align: center;
                font-family: Rubik;
                font-size: 2.5rem;
                font-weight: 600;
                margin-top: -5px;
              }

              &__textEn {
                color: #666c89;
                text-align: center;
                font-family: Krub;
                font-size: 1rem;
                font-weight: 500;
                margin-top: -8px;
              }
            }
          }

          &__right {
            margin-left: 1.875rem;
            position: relative;
            width: 30.375rem;

            &__title {
              color: #1c1f35;
              font-family: Alibaba PuHuiTi 2;
              font-size: 1.75rem;
              font-weight: 400;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &__content {
              color: #666c89;
              font-family: Alibaba PuHuiTi 2;
              font-size: 1rem;
              margin-top: 0.625rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 3;
              overflow: hidden;
              text-overflow: ellipsis;
            }

            &__toDetail {
              position: absolute;
              bottom: 0;
              color: var(--neutral-07100, #141718);
              width: 8rem;
              font-feature-settings:
                'clig' off,
                'liga' off;
              font-family: Alibaba PuHuiTi 2;
              font-size: 1.33194rem;
              font-weight: 500;
              line-height: 2.33088rem;
              letter-spacing: -0.03331rem;
              border-bottom: 1.332px solid var(--neutral-07100, #141718);
              padding-bottom: 0.3125rem;
            }
          }
        }

        .newsContainer-content__center__box__item__right__toDetail:hover {
          cursor: pointer;
          background: var(
            --3,
            linear-gradient(259deg, #ffa800 0%, #ff5207 100%)
          );
          background-clip: text;
          -webkit-background-clip: text;
          -webkit-text-fill-color: transparent;
          border-bottom: 1px solid #ed6e2d;
          .newsContainer-content__center__box__item__right__toDetailIcon {
            color: #ed6e2d;
          }
        }

        &__item:last-child {
          border-bottom: 1px solid #d6d6d6;
          padding-bottom: 2rem;
        }
      }

      &__Pagination {
        margin-bottom: 8rem;
        display: flex;
        justify-content: center;
        text-align: center;
        align-items: center;
      }
    }
  }
}

@root-entry-name: default;